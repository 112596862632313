<template>
  <form class="loginForm flex" name="form" @submit.prevent="handleLogin">
    <div class="form-group user w-1/2">
      <input
        id="loginUsername"
        v-model="user.username"
        class="form-control input"
        placeholder="Benutzer"
        name="username"
        @keyup="checkInput"
      />
    </div>
    <div class="form-group pass stroke w-1/2">
      <input
        id="loginPassword"
        v-model="user.password"
        type="password"
        class="form-control input"
        placeholder="Passwort"
        name="password"
        @keyup="checkInput"
      />
      <!--div
        v-if="errors.has('password')"
        class="alert alert-danger"
        role="alert"
      >Password is required!</div-->
    </div>
    <!--div class="form-group">
      <button class="btn" :disabled="loading">
        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
        <span>Login</span>
      </button>
    </div>
    <div class="form-group">
      <div v-if="message" class="alert alert-danger" role="alert">{{message}}</div>
    </div-->
  </form>
</template>

<script>
import User from "../models/user"
import { bus } from "@/main"

export default {
  name: "Login",
  data() {
    return {
      username: "",
      user: new User("", ""),
      loading: false,
      message: "",
    }
  },
  computed: {
    loggedIn() {
      bus.$emit("loggedIn")
      return this.$store.state.auth.status.loggedIn
    },
    hasUsername() {
      return this.user.username !== ""
    },
    hasPassword() {
      return this.user.password !== ""
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/archive")
    }
  },
  methods: {
    // eslint-disable-next-line
    checkInput(event) {
      if (event.keyCode === 13 && this.hasUsername && this.hasPassword) {
        this.handleLogin()
      }
    },
    checkUsername(e) {
      if (e.keyCode === 13) {
        if (!this.hasUsername) {
          //this.user.username = this.username;
          setTimeout(() => {
            if (document.getElementById("loginPassword") !== null)
              document.getElementById("loginPassword").focus()
          }, 100)
        }
      }
    },
    handleLogin() {
      this.loading = true
      this.$validator.validateAll().then(isValid => {
        if (!isValid) {
          this.loading = false
          return
        }
        if (this.user.username && this.user.password) {
          this.$store.dispatch("auth/login", this.user).then(
            () => {
              setTimeout(() => {
                bus.$emit("refreshArticles")
                if (
                  this.user.username === "admin" ||
                  this.user.username === "mathorn"
                ) {
                  localStorage.setItem("ewhAdmin", true)
                } else {
                  localStorage.removeItem("ewhAdmin")
                }
                this.$router.push("/archive")
              }, 10)
            },
            error => {
              this.loading = false
              this.user.password = ""
              this.message =
                (error.response && error.response.data) ||
                error.message ||
                error.toString()
            }
          )
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.btn {
  display: none;
}
.loginForm {
  border-top-left-radius: $lineHeightL/2;
  border-bottom-left-radius: $lineHeightL/2;
  background: $grey;
}
.input {
  border: none !important;

  background: transparent !important;
  height: $lineHeightL;
  width: calc(100% - 20px);
  padding-left: 30px;
  font-size: 20px;
  outline: none !important;
  background-color: transparent !important;
  &:autofill,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus,
  &:-internal-autofill-selected {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    outline: none !important;
  }
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: "basis-grotesque-mono-light-pro", sans-serif;
    font-size: 20px;
    color: #777;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    font-family: "basis-grotesque-mono-light-pro", sans-serif;
    font-size: 20px;
  }
  @media (max-width: 767px) {
    padding-left: 20px;
  }
}
</style>
