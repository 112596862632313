/*
export default class User {
  constructor(username, email, password) {
    this.username = username;
    this.email = email;
    this.password = password;
  }
}
*/
import { Model } from "@vuex-orm/core"
import Role from "@/models/role"
//import ArticleTag from '@/models/articleTag';
//import Article from '@/models/article';

export default class User extends Model {
  // entity is a required property for all models.
  static entity = "users"

  static primaryKey = "_id"

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {
      _id: this.attr(null),
      username: this.attr(""),
      email: this.attr(""),
      password: this.attr(""),
      roles: this.belongsToMany(Role),
    }
  }
}
