<template>
  <div class="home">
    <div ref="bg" class="bg"></div>
    <header class="header">
      <div class="w-1/3 flex items-center">
        <span class="mobile">EWH.</span>
        <span class="desktop">EWH. Das Archiv.</span>
      </div>
      <div class="w-2/3">
        <LoginWidget />
      </div>
    </header>
  </div>
</template>

<script>
import LoginWidget from "@/components/LoginWidget.vue"
import { bus } from "@/main"
export default {
  name: "home",
  components: {
    LoginWidget,
  },
  data() {
    return {
      show: true,
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  mounted() {
    bus.$on("loggedIn", () => {
      this.show = false
    })
  },
}
</script>

<style scoped lang="scss">
.bg {
  position: absolute;
  top: 76px;
  left: 0;
  width: 100%;
  height: calc(100% - 76px);
  z-index: 0;
  background: $grey;
  background-image: url(../assets/bg.jpg);
  background-position: center;
  background-size: cover;
  //background-repeat: no-repeat;
  opacity: 1;
  &.faded {
    opacity: 0;
    transition: opacity 2s;
  }
  @media (max-width: 768px) {
    background-size: cover;
  }
}
.home {
  width: 100%;
  height: 100%;
  background: white;
  .header {
    display: flex;
    border-bottom: 1px solid $black;
    font-size: 20px;
    & > div {
      padding-left: 30px;
      &.pw {
        padding-left: 0;
      }
    }
    span.mobile {
      display: none;
    }

    @media (max-width: 920px) {
      span.mobile {
        display: block;
      }
      span.desktop {
        display: none;
      }
    }
  }
  @media (max-width: 767px) {
    .header {
      height: $offsetTwo;
      flex-wrap: wrap;
      & > div {
        height: $lineHeightL;

        flex: 0 0 auto;
        padding-left: 20px;
        &.pw {
          border-top: 1px solid black;
          padding: 0;
          width: 100vw;
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
</style>
