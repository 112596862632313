<template>
  <div class="admin">
    <div class="header flex justify-between items-center px-8">
      <div>EWH.Admin</div>
      <div class="right">
        <div @click="$router.back()">
          <RoundButton color="orange" icon="close" text="Schließen" />
        </div>
      </div>
    </div>
    <div ref="wrapper" class="wrapper">
      <h2 class="bg-stone-900 p-4 text-white mb-4">Stats</h2>
      <p>Total articles count: {{ stats.articlesTotalCount }}</p>
      <br />
      <p>Articles in user accounts: {{ stats.articlesUsersCount }}</p>
      <p>Business/BUS: {{ stats.articlesBusinessCount }}</p>
      <p>Literatur/LIT: {{ stats.articlesLiteraturCount }}</p>
      <p>Privat/PRI: {{ stats.articlesPrivatCount }}</p>
      <br />
      <h2 class="bg-stone-900 p-4 text-white">Users</h2>
      <div class="mb-16">
        <p class="flex border-t border-solid border-black p-4 text-sm italic">
          <span class="block w-1/4">
            Username
          </span>
          <span class="block w-1/4">
            Email
          </span>
          <span class="block w-1/4">
            Role
          </span>
          <span class="block w-1/4">
            Tag
          </span>
        </p>
        <p
          v-for="user in users"
          :key="user._id"
          class="flex border-t border-solid border-black p-4"
        >
          <span class="block w-1/4">
            {{ user.username }}
          </span>
          <span class="block w-1/2">
            {{ user.email }}
          </span>
          <span class="block w-1/4">
            {{ user.roles[0].name }}
          </span>
          <span class="block w-1/4">
            {{ getUserTag(user.roles[0].name) }}
          </span>
        </p>
      </div>

      <h2 class="bg-stone-900 p-4 text-white">Tags</h2>
      <div class="tags">
        <div v-for="tag in sortedTags" :key="tag.key">
          <AdminRow
            :tag="tag"
            :roles="roles"
            @onUpdate="updateTag"
            @onDelete="deleteTag"
            @onEdit="editTag"
          />
        </div>
      </div>

      <div class="newTag">
        <input v-model="nkey" placeholder="Tag key" />
        <input v-model="nname" placeholder="Tag name" />
        <button @click="createTag">Neues Tag erstellen</button>
      </div>
    </div>
  </div>
</template>

<script>
import RoundButton from "@/components/RoundButton.vue"
import AdminRow from "@/components/AdminRow.vue"
import ArticleService from "../services/article"
import TagService from "../services/tag"
import RoleService from "../services/role"
import UserService from "../services/user"

import { createInstance, resource } from "vuex-pagination"

//import Article from '@/models/article';

//import { bus } from "@/main";

export default {
  name: "archive",
  components: {
    AdminRow,
    RoundButton,
  },
  data() {
    return {
      users: [],
      roles: new Array(),
      scrollPos: 0,
      currentPage: 1,
      nkey: "",
      nname: "",
      stats: {},
    }
  },
  computed: {
    tags: createInstance("tags", {
      page: 1,
      pageFrom: 1,
      pageSize: 100,
    }),
    sortedTags() {
      if (typeof this.tags !== "undefined" && this.tags.items) {
        const st = this.tags.items.slice(0).sort((a, b) => {
          const nameA = a.key.toUpperCase() // ignore upper and lowercase
          const nameB = b.key.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
        return st
      }
      return []
    },
  },
  created() {
    //this.$store.dispatch('getTags');
    //this.$store.dispatch('getArticles');
  },
  updated() {
    //debugger;
  },
  mounted() {
    RoleService.getRoles().then(
      response => {
        this.roles = response.data
      },
      error => {
        console.log(error)
      }
    )

    ArticleService.getStats().then(
      response => {
        this.stats = response.data
      },
      error => {
        console.log(error)
      }
    )

    UserService.getUsers().then(
      response => {
        this.users = response.data
      },
      error => {
        console.log(error)
      }
    )
  },
  methods: {
    getUserTag(roleName) {
      switch (roleName) {
        case "business":
          return "BUS"
        case "literatur":
          return "LIT"
        case "privat":
          return "PRI"
      }
    },
    refreshTags() {
      resource("tags").refresh()
    },
    saveTag(opts) {
      console.log(opts)
      debugger
    },
    updateTag(key, name, roleId) {
      TagService.updateTag(key, name, roleId).then(
        () => {
          this.refreshTags()
        },
        () => {
          debugger
        }
      )
    },
    editTag(tag) {
      console.log(tag)
      debugger
    },
    deleteTag(id) {
      if (confirm("Sicher?")) {
        TagService.deleteTag(id).then(
          () => {
            this.refreshTags()
          },
          () => {
            debugger
          }
        )
      }
    },
    createTag() {
      if (this.nkey === "" || this.nname === "") return
      TagService.createTag(this.nkey, this.nname).then(
        () => {
          this.refreshTags()
          this.nname = ""
          this.nkey = ""
        },
        () => {
          debugger
        }
      )
    },
  },
}
</script>
<style lang="scss">
.logout {
  position: fixed;
  right: 20px;
  bottom: 20px;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    border-bottom: 2px solid $orange;
  }
}
.newTag {
  width: 100%;
  margin: 0 auto;
  input {
    padding: 4px;
    margin: 4px;
  }
}
.admin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .tags {
    width: 100%;
    margin: 0 auto;
  }
  .wrapper {
    position: absolute;
    background: $grey;
    padding: 30px;
    top: $lineHeightL;
    left: 0;
    right: 0;
    bottom: 0;
    //height: calc(100% - $lineHeightL);
    overflow: scroll;
  }
}
</style>
