import Vue from "vue"
import App from "./App.vue"
//import "./registerServiceWorker";
import router from "./router"
import store from "./store"

import "../node_modules/slick-carousel/slick/slick.css"

import "./scss/tailwind.css"

Vue.config.productionTip = false

import VueZoomer from "vue-zoomer"
Vue.use(VueZoomer)

import VeeValidate from "vee-validate"
Vue.use(VeeValidate)

import "viewerjs/dist/viewer.css"
import Viewer from "v-viewer"
Vue.use(Viewer)

import panZoom from "vue-panzoom"
Vue.use(panZoom)

import Notifications from "vue-notification"
Vue.use(Notifications)

import infiniteScroll from "vue-infinite-scroll"
Vue.use(infiniteScroll)

import VTooltip from "v-tooltip"
Vue.use(VTooltip)

import VueScrollTo from "vue-scrollto"
Vue.use(VueScrollTo)

import VueScroll from "vue-scroll"
Vue.use(VueScroll, { throttle: 300 })

export const bus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
