import axios from "axios"

const API_URL = process.env.VUE_APP_API_URL

class AuthService {
  login(user) {
    return axios({
      method: "post",
      url: API_URL + "auth/signin",
      data: {
        username: user.username,
        password: user.password,
      },
    }).then(response => {
      //debugger;
      if (response.data.accessToken) {
        //response.data.accessToken = response.data.token;
        localStorage.setItem("user", JSON.stringify(response.data))
      }

      return response.data
    })
  }

  logout() {
    localStorage.removeItem("user")
  }

  register(user) {
    return axios.post(API_URL + "register", {
      username: user.username,
      email: user.email,
      password: user.password,
    })
  }
}

export default new AuthService()
