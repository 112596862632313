import { render, staticRenderFns } from "./AdminRow.vue?vue&type=template&id=1a01931b&scoped=true"
import script from "./AdminRow.vue?vue&type=script&lang=js"
export * from "./AdminRow.vue?vue&type=script&lang=js"
import style0 from "./AdminRow.vue?vue&type=style&index=0&id=1a01931b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a01931b",
  null
  
)

export default component.exports