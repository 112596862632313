<template>
  <div id="app">
    <transition name="slide">
      <keep-alive include="archive" :key="page_key">
        <router-view />
      </keep-alive>
    </transition>
    <notifications group="alerts" />
  </div>
</template>

<script>
//import { createInstance } from "vuex-pagination";

export default {
  data() {
    return {
      page_key: Math.random(),
    }
  },
  computed: {
    /*
    tags: createInstance("tags", {
      page: 1,
      pageFrom: 1,
      pageSize: 100
    }),
    */
    currentUser() {
      return this.$store.state.auth.user
    },
    isAdmin() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ADMIN")
      }
      return false
    },
  },
}
</script>
<style lang="scss">
@font-face {
  font-family: "basis-grotesque-mono-light-pro";
  src: url("./assets/fonts/basis-grotesque-mono-light-pro.eot");
  src: url("./assets/fonts/basis-grotesque-mono-light-pro.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/basis-grotesque-mono-light-pro.woff2") format("woff2"),
    url("./assets/fonts/basis-grotesque-mono-light-pro.woff") format("woff"),
    url("./assets/fonts/basis-grotesque-mono-light-pro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html,
body,
button,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
select,
small,
strike,
strong,
sub,
sup,
tt,
var,
input,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "basis-grotesque-mono-light-pro", sans-serif;
  font-weight: 200;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  background: #222;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
div {
  box-sizing: border-box;
}

#app {
  background: $grey_light;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: white;
    color: black;
    border-radius: 16px;
    padding: 6px 12px 6px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: white;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
</style>
