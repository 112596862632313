var render = function render(){var _vm=this,_c=_vm._self._c;return (!['BUS', 'PRI', 'LIT'].includes(_vm.tag.key))?_c('div',{staticClass:"admin-tag"},[_c('div',{staticClass:"id"},[_vm._v(_vm._s(_vm.tag.key))]),_c('div',{staticClass:"name"},[(_vm.isEditing)?_c('div',{staticClass:"edit"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tname),expression:"tname"}],attrs:{"placeholder":"Tag name"},domProps:{"value":(_vm.tname)},on:{"input":function($event){if($event.target.composing)return;_vm.tname=$event.target.value}}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.troles),expression:"troles"}],attrs:{"multiple":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.troles=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.roles),function(role){return _c('option',{key:role._id,domProps:{"value":role._id}},[_vm._v(_vm._s(role.name))])}),0),_c('button',{on:{"click":function($event){_vm.isEditing = false}}},[_vm._v("Abbrechen")]),_c('button',{on:{"click":function($event){_vm.isEditing = false
          _vm.$emit('onUpdate', _vm.tag.key, _vm.tname, _vm.troles)}}},[_vm._v(" Speichern ")])]):_c('div',{on:{"click":function($event){_vm.isEditing = !['BUS', 'PRI', 'LIT'].includes(_vm.tag.key)}}},[_c('span',{staticClass:"inline-block w-72"},[_vm._v(" "+_vm._s(_vm.tag.name)+" ")]),(
          _vm.roles &&
            _vm.roles.length > 0 &&
            typeof _vm.roles.find === 'function' &&
            _vm.tag.roles &&
            _vm.tag.roles !== []
        )?_c('span',_vm._l((_vm.tag.roles),function(role){return _c('span',{key:role,staticClass:"inline-block mr-2 bg-gray-300 p-2"},[_vm._v(" "+_vm._s(_vm.roles.find(e => e._id == role).name)+" ")])}),0):_vm._e()])]),_c('div',{staticClass:"btns"},[(!['BUS', 'PRI', 'LIT'].includes(_vm.tag.key))?_c('button',{on:{"click":function($event){return _vm.$emit('onDelete', _vm.tag._id)}}},[_vm._v(" Löschen ")]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }