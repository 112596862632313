<template>
  <div v-if="!['BUS', 'PRI', 'LIT'].includes(tag.key)" class="admin-tag">
    <div class="id">{{ tag.key }}</div>
    <div class="name">
      <div v-if="isEditing" class="edit">
        <input v-model="tname" placeholder="Tag name" />
        <select v-model="troles" multiple>
          <option v-for="role in roles" :key="role._id" :value="role._id">{{
            role.name
          }}</option>
        </select>
        <button @click="isEditing = false">Abbrechen</button>
        <button
          @click="
            isEditing = false
            $emit('onUpdate', tag.key, tname, troles)
          "
        >
          Speichern
        </button>
      </div>
      <div v-else @click="isEditing = !['BUS', 'PRI', 'LIT'].includes(tag.key)">
        <span class="inline-block w-72">
          {{ tag.name }}
        </span>
        <span
          v-if="
            roles &&
              roles.length > 0 &&
              typeof roles.find === 'function' &&
              tag.roles &&
              tag.roles !== []
          "
        >
          <span
            v-for="role in tag.roles"
            :key="role"
            class="inline-block mr-2 bg-gray-300 p-2"
          >
            {{ roles.find(e => e._id == role).name }}
          </span>
        </span>
      </div>
    </div>

    <div class="btns">
      <button
        v-if="!['BUS', 'PRI', 'LIT'].includes(tag.key)"
        @click="$emit('onDelete', tag._id)"
      >
        Löschen
      </button>
    </div>
  </div>
</template>

<script>
import TagService from "@/services/tag"

export default {
  name: "admin",
  components: {},
  data() {
    return {
      tname: "",
      troles: [],
      title: "",
      isEditing: false,
    }
  },
  props: {
    tag: {
      type: Object,
    },
    roles: {
      type: Array,
    },
  },
  mounted() {
    this.tname = this.tag.name
    this.troles = this.tag.roles
    this.title = `${this.tag.key} - ${this.tag.name}`
  },
  methods: {
    deleteTag() {
      TagService.deleteTag(this.tag.key).then(() => {})
    },
    saveTag() {
      TagService.updateTag(this.tag.key, this.tname, this.troles).then(
        response => {
          this.tname = response.data.name
          this.title = `${this.tag.key} - ${this.tname}`
          this.isEditing = false
        },
        () => {
          debugger
        }
      )
    },
  },
}
</script>

<style scoped lang="scss">
.admin-tag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4px;
  box-sizing: border-box;

  &:hover {
    background: darken($grey, 5%);
  }

  .id {
    width: 256px;
  }
  .name {
    flex: 1 1 auto;
    text-align: left;
  }

  .title {
    color: red;
    flex: 1 1 auto;
  }
  .btns {
    flex: 0 0 auto;
  }

  .edit {
    width: 100%;
    display: flex;
  }
  button {
    padding: 4px;
    margin: 4px;
    cursor: pointer;
  }
}
</style>
