import axios from "axios"
import authHeader from "./auth-header"

const API_URL = process.env.VUE_APP_API_URL

class TagService {
  getTags() {
    return axios.get(`${API_URL}tags`, { headers: authHeader() })
  }

  getTag(hash) {
    return axios.get(`${API_URL}tags/${hash}`, { headers: authHeader() })
  }

  updateTag(key, name, roleIds) {
    const data = {
      name,
      roles: roleIds,
    }
    return axios.post(`${API_URL}tags/${key}`, data, { headers: authHeader() })
  }

  deleteTag(id) {
    return axios.delete(`${API_URL}tags/${id}`, { headers: authHeader() })
  }

  createTag(key, name) {
    const data = {
      key,
      name,
    }
    return axios.post(`${API_URL}tags/`, data, { headers: authHeader() })
  }
}

export default new TagService()
