import axios from "axios"
import authHeader from "./auth-header"

const API_URL = process.env.VUE_APP_API_URL

class UserService {
  getUsers() {
    return axios.get(`${API_URL}users`, { headers: authHeader() })
  }

  getUser(hash) {
    return axios.get(`${API_URL}users/${hash}`, { headers: authHeader() })
  }
}

export default new UserService()
