import Vue from "vue"
import Vuex from "vuex"

import { auth } from "./auth.module"

import axios from "axios"
import authHeader from "../services/auth-header"

import { bus } from "@/main"

Vue.use(Vuex)

Vue.use(PaginationPlugin)

const API_URL = process.env.VUE_APP_API_URL

import { createResource, resource, PaginationPlugin } from "vuex-pagination"

const store = new Vuex.Store({
  //plugins: [VuexORM.install(database)],
  strict: true,
  state: {
    isAdmin: false,
  },
  mutations: {
    loginAdmin(state) {
      state.isAdmin = true
    },
    logoutAdmin(state) {
      state.isAdmin = false
    },
    rateArticle(state, payload) {
      /*
      const resource = resource("articles")
      debugger
      const article = state.articles.find(
        article => article.hash === payload.hash
      )
      const index = state.articles.indexOf(article)
      article.rating = payload.rating
      state.articles[index] = article
      */
    },
  },
  actions: {
    loginAdmin({ commit }) {
      commit("loginAdmin")
    },
    logoutAdmin({ commit }) {
      commit("logoutAdmin")
    },
    rateArticle({ commit }, payload) {
      commit("rateArticle", payload)
    },
    refreshTags() {
      //resource("tags").refresh();
    },
    searchArticles({ commit }, payload) {
      //commit("searchArticles", payload)
      resource("articles").refresh()
    },
  },
  getters: {
    loggedIn: state => {
      return state.isAdmin
    },
  },
  modules: {
    auth,
  },
})

async function fetchTags(opts) {
  let query = {
    p: opts.page,
  }
  let tags = await axios
    .get(`${API_URL}tags`, { headers: authHeader(), params: query })
    .then(response => {
      return response.data
    })

  return {
    total: tags.length,
    data: tags,
  }
}

async function fetchArticles(opts) {
  let keywords = opts.args.keywords ? opts.args.keywords : false
  let tags = opts.args.tags ? opts.args.tags : []

  let query = {
    p: opts.page,
  }
  if (keywords) query["s"] = keywords
  if (tags && tags.length > 0) query["t"] = tags
  if (opts.args.dfy) query["dfy"] = opts.args.dfy
  if (opts.args.dfm) query["dfm"] = opts.args.dfm
  if (opts.args.dty) query["dty"] = opts.args.dty
  if (opts.args.dtm) query["dtm"] = opts.args.dtm

  let articles = await axios
    .get(`${API_URL}articles`, { headers: authHeader(), params: query })
    .then(response => {
      return response.data
    })
    .catch(error => {
      console.log(error)
      if (error.response.status === 401) {
        bus.$emit("logOut")
      }
      return {
        total: 0,
        data: [],
      }
    })

  return {
    total: articles.total,
    data: articles.data,
  }
}

createResource("articles", fetchArticles, {
  prefetch: false,
})

createResource("tags", fetchTags, {
  prefetch: false,
  cacheResources: 0,
})

export default store
