import { Model } from "@vuex-orm/core"
//import ArticleTag from '@/models/articleTag';
//import Article from '@/models/article';

export default class Role extends Model {
  // entity is a required property for all models.
  static entity = "roles"

  static primaryKey = "_id"

  // List of all fields (schema) of the post model. `this.attr()` declares
  // a generic field type with a default value as the first argument.
  static fields() {
    return {
      _id: this.attr(null),
      name: this.attr(""),
      //articles: this.belongsToMany(Article, ArticleTag, 'tag_id', 'article_id')
    }
  }
}
