import axios from "axios"
import authHeader from "./auth-header"

const API_URL = process.env.VUE_APP_API_URL

class RoleService {
  getRoles() {
    return axios.get(`${API_URL}roles`, { headers: authHeader() })
  }

  getRole(hash) {
    return axios.get(`${API_URL}roles/${hash}`, { headers: authHeader() })
  }

  deleteRole(id) {
    return axios.delete(`${API_URL}roles/${id}`, { headers: authHeader() })
  }

  createRole(name) {
    const data = {
      name,
    }
    return axios.post(`${API_URL}roles/`, data, { headers: authHeader() })
  }
}

export default new RoleService()
